<template>
  <div>
    <input-control
      type="vertical"
      labelClass="col-md-3 p-0 font-weight-bold"
      v-model="(patient.user || {}).bio"
      control="textarea"
      wrapClass="budget-info-opacity-1"
      inputClass="light-shadow radius-36"
      readonly
      >Approved Hours</input-control
    >
    <button
      class="btn btn-black mb-3"
      @click="openAddmaxhoursModal"
      v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Budget
    </button>

    <modal
      class="update-maxhours-modal"
      name="update-maxhours-modal"
      transition="pop-out"
      :height="500"
      :width="600"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeUpdatemaxhoursModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-inner-container">
          <div class="card-body">
            <form
              @submit.prevent="
                updatemaxhoursMiddle({
                  user_id: patient.user_id,
                  maxhours: selectedmaxhours,
                })
              "
              validate
            >
              <div class="budget-container mb-4">
                <div class="row">
                  <div class="form-group col-12 col-md-5 mb-1">
                    <input-control
                      v-model.number="selectedmaxhours.budget"
                      control="number"
                      step="0.01"
                      min="0"
                      wrapClass="align-items-center"
                      type="vertical"
                      inputClass="dollar-sign"
                      :disabled="viewOnly"
                    >
                      Total Budget
                    </input-control>
                  </div>
                  <div class="form-group col-12 col-md-4 mb-1">
                    <input-control
                      v-model.number="selectedmaxhours.period_value"
                      control="number"
                      step="0.01"
                      min="0"
                      :required="Boolean(selectedmaxhours.budget)"
                      wrapClass="align-items-center"
                      type="vertical"
                      inputClass="time-sign"
                      :disabled="viewOnly"
                    >
                      Period Value
                    </input-control>
                  </div>
                  <div class="form-group col-12 col-md-3 mb-1">
                    <label>Period Type</label>
                    <select
                      class="form-control"
                      :required="true"
                      v-model="selectedmaxhours.period_type"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="item in periodOptions"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-6 col-12"
                    v-if="
                      selectedmaxhours.budget && selectedmaxhours.period_value
                    "
                  >
                    <label>
                      Defined Budget
                      <i
                        class="fa fa-info-circle ml-2 pointer-cursor"
                        v-tooltip.top="
                          `Defined Budget = Total Budget divided by the Period Value`
                        "
                      ></i>
                    </label>
                    <div class="calculated-budget border rounded py-2">
                      <div>
                        {{
                          parseFloat(getDividedBudgetFrom("selectedmaxhours"))
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group col-md-6 col-12"
                    v-if="selectedmaxhours.budget && viewOnly"
                  >
                    <label>
                      Remaining Balance
                      <i
                        class="fa fa-info-circle ml-2 pointer-cursor"
                        v-tooltip.top="
                          `Remaining Balance = Total Budget - Spent amount for selected service`
                        "
                      ></i>
                    </label>
                    <div
                      :class="
                        getRemainingBalance >= 0
                          ? `actual-budget-above-zero`
                          : `actual-budget-below-zero`
                      "
                      class="calculated-budget py-2"
                    >
                      <div>
                        {{
                          parseFloat(getRemainingBalance)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <small class="text-muted" v-if="selectedmaxhours.budget">
                  Note: Remaining Balance value is measured from
                  <span class="text-bold text-dark">
                    {{ prettyDate(selectedmaxhours.created_at, true) }}
                  </span>
                  to
                  <span class="text-bold text-dark">
                    {{ prettyDate(getNow, true) }}
                  </span>
                </small>
              </div>

              <div class="row">
                <div
                  class="form-group col-12 col-md-6 mb-3 mb-md-4"
                  v-if="displayedParentServicesToEdit.length"
                >
                  <label>Service</label>
                  <select
                    class="form-control"
                    :required="true"
                    :disabled="viewOnly"
                    v-model="selectedmaxhours.service_id"
                    @change="
                      displayRelevantSubServices(selectedmaxhours.service_id)
                    "
                  >
                    <option
                      v-for="item in displayedParentServicesToEdit"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <alert
                    v-if="!displayedParentServicesToEdit.length"
                    class="col-12 text-center mb-3 mb-md-4"
                    >No services attached to the client yet</alert
                  >
                </div>

                <input-control
                  v-model="selectedmaxhours.value"
                  :required="true"
                  :disabled="viewOnly"
                  v-if="displayedParentServicesToEdit.length"
                  wrapClass="align-items-center"
                  type="vertical"
                  class="col-6 col-md-2"
                  control="number"
                  step="0.01"
                  min="0"
                >
                  Hours
                </input-control>
              </div>
              <div
                class="form-group sub-services-container"
                v-if="showSubServices"
              >
                <div
                  class="row form-group"
                  v-for="(subService, index) in relevantSubServices"
                  :key="subService.id"
                >
                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <input
                      class="col form-control"
                      :value="subService.name"
                      wrapClass="align-items-center"
                      type="vertical"
                      disabled="disabled"
                    />
                  </div>
                  <div class="col-6 col-md-2">
                    <input-control
                      v-model="subService.value"
                      @input="setSubServiceValue(index, $event)"
                      type="vertical"
                      control="number"
                      placeholder="Hours"
                      wrapClass="mb-0"
                      labelClass="d-none"
                      :disabled="viewOnly"
                      step="0.01"
                      min="0"
                      >Hours</input-control
                    >
                  </div>
                </div>
              </div>
              <div class="text-center border-top pt-4">
                <save :saving="isSaving" v-if="!viewOnly">Update Budget</save>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="delete-maxhours-modal"
      name="delete-maxhours-modal"
      transition="pop-out"
      :height="310"
      :width="550"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeDeletemaxhoursModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div>
          <div class="card-body text-center">
            <h3>
              Are you sure you want to delete the budget for service
              {{ selectedmaxhours.service_name }} ?
            </h3>

            <div class="row">
              <div
                class="col-md-8"
                style="margin: 25px auto; text-align: center"
              >
                <save
                  classes="
                    btn btn-danger
                    col-md-4
                    mr-5
                    mb-3 mb-md-0
                    maxhours-confirm-delete-btns
                  "
                  @click="
                    deleteSelectedmaxhoursMiddle({
                      user_id: patient.user_id,
                      maxhoursId: selectedmaxhours.id,
                    })
                  "
                  :saving="isDeleting"
                >
                  Yes
                </save>
                <button
                  class="btn btn-theme col-md-4"
                  @click="closeDeletemaxhoursModal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="add-maxhours-modal"
      name="add-maxhours-modal"
      transition="pop-out"
      :height="500"
      :width="600"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeAddmaxhoursModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-inner-container">
          <div class="card-body">
            <form
              @submit.prevent="
                addNewmaxhoursMiddle({
                  patientId: patient.id,
                  user_id: patient.user_id,
                  newmaxhours,
                })
              "
              validate
            >
              <div class="row budget-container mb-4">
                <div class="form-group col-12 col-md-5 mb-1">
                  <input-control
                    v-model.number="newmaxhours.budget"
                    control="number"
                    step="0.01"
                    min="0"
                    wrapClass="align-items-center"
                    type="vertical"
                    inputClass="dollar-sign"
                  >
                    Total Budget
                  </input-control>
                </div>
                <div class="form-group col-12 col-md-4 mb-1">
                  <input-control
                    v-model.number="newmaxhours.period_value"
                    control="number"
                    step="0.01"
                    min="0"
                    :required="Boolean(newmaxhours.budget)"
                    wrapClass="align-items-center"
                    type="vertical"
                    inputClass="time-sign"
                  >
                    Period Value
                  </input-control>
                </div>
                <div class="form-group col-12 col-md-3 mb-1">
                  <label>Period Type</label>
                  <select
                    class="form-control"
                    :required="true"
                    v-model="newmaxhours.period_type"
                  >
                    <option
                      v-for="item in periodOptions"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.value }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-group col-12"
                  v-if="newmaxhours.budget && newmaxhours.period_value"
                >
                  <label>Defined Budget</label>
                  <div class="calculated-budget border rounded py-2">
                    <div>
                      {{
                        parseFloat(getDividedBudgetFrom("newmaxhours"))
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mx-0">
                <div
                  class="form-group col-12 col-md-6 mb-3 mb-md-4"
                  v-if="displayedParentServicesToAdd.length"
                >
                  <label>Service</label>
                  <select
                    class="form-control"
                    :required="true"
                    v-model="newmaxhours.service_id"
                    @change="displayRelevantSubServices(newmaxhours.service_id)"
                  >
                    <option
                      v-for="item in displayedParentServicesToAdd"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <alert
                  v-if="!displayedParentServicesToAdd.length"
                  class="col-12 text-center mb-3 mb-md-4"
                  >{{
                    !clientServices || !clientServices.length
                      ? "No services attached to the client yet"
                      : "Budget already added to all client services"
                  }}</alert
                >

                <input-control
                  v-model="newmaxhours.value"
                  :required="true"
                  v-if="displayedParentServicesToAdd.length"
                  control="number"
                  step="0.01"
                  min="0"
                  wrapClass="align-items-center"
                  type="vertical"
                  class="col-6 col-md-2"
                >
                  Hours
                </input-control>
              </div>

              <div
                class="form-group sub-services-container"
                v-if="showSubServices"
              >
                <div
                  class="row form-group"
                  v-for="(subService, index) in relevantSubServices"
                  :key="subService.id"
                >
                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <input
                      class="form-control"
                      :value="subService.name"
                      wrapClass="align-items-center"
                      type="vertical"
                      disabled="disabled"
                    />
                  </div>
                  <div class="col-6 col-md-2">
                    <input-control
                      v-model="subService.value"
                      @input="setSubServiceValue(index, $event)"
                      type="vertical"
                      control="number"
                      placeholder="Hours"
                      wrapClass="mb-0"
                      labelClass="d-none"
                      step="0.01"
                      min="0"
                      >Hours</input-control
                    >
                  </div>
                </div>
              </div>
              <div class="text-center border-top pt-4">
                <save :saving="isSaving">Add Budget</save>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="renew-budget-modal"
      name="renew-budget-modal"
      transition="pop-out"
      :height="550"
      :width="600"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeRenewBudgetModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-inner-container">
          <div class="card-body">
            <form
              @submit.prevent="
                renewBudget({
                  user_id: patient.user_id,
                  maxhours: selectedmaxhours,
                })
              "
              validate
            >
              <div class="budget-container mb-4">
                <div class="row">
                  <div class="form-group col-12 col-md-5 mb-1">
                    <input-control
                      v-model.number="selectedmaxhours.budget"
                      control="number"
                      step="0.01"
                      min="0"
                      wrapClass="align-items-center"
                      type="vertical"
                      inputClass="dollar-sign"
                      :disabled="viewOnly"
                    >
                      Total Budget
                    </input-control>
                  </div>
                  <div class="form-group col-12 col-md-4 mb-1">
                    <input-control
                      v-model.number="selectedmaxhours.period_value"
                      control="number"
                      step="0.01"
                      min="0"
                      :required="Boolean(selectedmaxhours.budget)"
                      wrapClass="align-items-center"
                      type="vertical"
                      inputClass="time-sign"
                      :disabled="viewOnly"
                    >
                      Period Value
                    </input-control>
                  </div>
                  <div class="form-group col-12 col-md-3 mb-1">
                    <label>Period Type</label>
                    <select
                      class="form-control"
                      :required="true"
                      v-model="selectedmaxhours.period_type"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="item in periodOptions"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-6 col-12"
                    v-if="
                      selectedmaxhours.budget && selectedmaxhours.period_value
                    "
                  >
                    <label>
                      Defined Budget
                      <i
                        class="fa fa-info-circle ml-2 pointer-cursor"
                        v-tooltip.top="
                          `Defined Budget = Total Budget divided by the Period Value`
                        "
                      ></i>
                    </label>
                    <div class="calculated-budget border rounded py-2">
                      <div>
                        {{
                          parseFloat(getDividedBudgetFrom("selectedmaxhours"))
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group col-md-6 col-12"
                    v-if="selectedmaxhours.budget"
                  >
                    <label>
                      Remaining Balance
                      <i
                        class="fa fa-info-circle ml-2 pointer-cursor"
                        v-tooltip.top="
                          `Remaining Balance = Total Budget - Spent amount for selected service`
                        "
                      ></i>
                    </label>
                    <div
                      :class="
                        getRemainingBalance >= 0
                          ? `actual-budget-above-zero`
                          : `actual-budget-below-zero`
                      "
                      class="calculated-budget py-2"
                    >
                      <div>
                        {{
                          parseFloat(getRemainingBalance)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <small class="text-muted" v-if="selectedmaxhours.budget">
                  Note: Remaining Balance value is measured from
                  <span class="text-bold text-dark">{{
                    prettyDate(selectedmaxhours.created_at, true)
                  }}</span>
                  to
                  <span class="text-bold text-dark">{{
                    prettyDate(getNow, true)
                  }}</span>
                </small>
              </div>
              <div class="row">
                <div class="form-group col-12 col-md-6 mb-3 mb-md-4">
                  <label>Service</label>
                  <select
                    class="form-control"
                    :required="true"
                    disabled
                    v-model="selectedmaxhours.service_id"
                    @change="
                      displayRelevantSubServices(selectedmaxhours.service_id)
                    "
                  >
                    <option
                      v-for="item in displayedParentServicesToEdit"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <input-control
                  v-model="selectedmaxhours.value"
                  :required="true"
                  :disabled="viewOnly"
                  wrapClass="align-items-center"
                  type="vertical"
                  class="col-6 col-md-2"
                  control="number"
                  step="0.01"
                  min="0"
                >
                  Hours
                </input-control>
              </div>
              <div
                class="form-group sub-services-container"
                v-if="showSubServices"
              >
                <div
                  class="row form-group"
                  v-for="(subService, index) in relevantSubServices"
                  :key="subService.id"
                >
                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <input
                      class="col form-control"
                      :value="subService.name"
                      wrapClass="align-items-center"
                      type="vertical"
                      disabled="disabled"
                    />
                  </div>
                  <div class="col-6 col-md-2">
                    <input-control
                      v-model="subService.value"
                      @input="setSubServiceValue(index, $event)"
                      type="vertical"
                      control="number"
                      placeholder="Hours"
                      wrapClass="mb-0"
                      labelClass="d-none"
                      :disabled="viewOnly"
                      step="0.01"
                      min="0"
                      >Hours</input-control
                    >
                  </div>
                </div>
              </div>
              <div class="text-center border-top pt-4">
                <save :saving="isSaving" v-if="!viewOnly">Renew Budget</save>
                <i
                  class="fa fa-info-circle ml-2 pointer-cursor"
                  v-tooltip.top="
                    `After budget renewal, the Remaining Balance value will be measured from <br>Today (${prettyDate(
                      getNow,
                      true
                    )})`
                  "
                ></i>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="budget-history-modal"
      name="budget-history-modal"
      transition="pop-out"
      :height="500"
      :width="850"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeBudgetHistoryModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-inner-container py-3 px-3">
          <BudgetHistoryModel :maxHours="budgetHistoryData" />
        </div>
      </div>
    </modal>
    <alert class="my-4 light-shadow" v-if="maxhours.isLoading" />
    <div>
      <alert
        class="my-4 light-shadow"
        v-if="!maxhours.isLoading && (!maxhours.data || !maxhours.data.length)"
        >No Budgets</alert
      >
    </div>
    <div
      class="
        card
        overflow-auto
        shadow-card
        mobile-tablet-no-card
        table-container
        border border-lg-0
      "
      v-if="!maxhours.isLoading && maxhours.data && maxhours.data.length"
    >
      <table
        class="table table-sm table-striped mb-0 responsive-mobile-tablet-table"
      >
        <thead>
          <tr>
            <th>Service</th>
            <th>Limit</th>
            <th>Total Budget</th>
            <th class="text-center">Period Value</th>
            <th>
              <div class="d-inline-flex align-items-center text-center">
                Defined Budget
                <i
                  class="fa fa-info-circle ml-2 pointer-cursor"
                  v-tooltip.top="
                    `Defined Budget = Total Budget divided by the Period Value<br/>
                     Note: Defined Budget = Total Budget if the period value not exist`
                  "
                ></i>
              </div>
            </th>
            <th>Created at</th>
            <th class="text-center">Action</th>
            <th>History</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="maxhour in maxhours.data" :key="maxhour.id">
            <td>
              <b class="mobile-tablet-only mr-2">Service Name:</b>
              <button
                class="btn p-2 border-0 btn-link text-left"
                @click="openUpdatemaxhoursModal(maxhour, true)"
              >
                {{ maxhour.service_name }}
              </button>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Limit:</b
              >{{ maxhour.totalValue }} hrs / {{ maxhour.period_type }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Total Budget:</b>
              <span
                :class="{
                  'text-danger': maxhour.is_exhausted,
                }"
              >
                {{
                  maxhour.budget
                    ? `$${parseFloat(maxhour.budget).toFixed(2)}`
                    : "N/A"
                }}
              </span>
              <span
                v-if="maxhour.is_exhausted"
                class="badge badge-pill badge-danger ml-1"
              >
                Exhausted
              </span>
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Period Value:</b
              >{{
                maxhour.budget && maxhour.period_value
                  ? maxhour.period_value
                  : "N/A"
              }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">
                Defined Budget
                <i
                  class="fa fa-info-circle ml-2 pointer-cursor"
                  v-tooltip.top="
                    `Defined Budget = Total Budget divided by the Period Value<br/>
                  Note: Defined Budget = Total Budget if the period value not exist`
                  "
                ></i>
                :
              </b>
              {{
                maxhour.budget
                  ? `$${parseFloat(
                      maxhour.budget / (maxhour.period_value || 1)
                    ).toFixed(2)} / ${maxhour.period_type}`
                  : "N/A"
              }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created At:</b
              >{{ prettyDate(maxhour.created_at) }}
            </td>

            <td class="text-center td-actions">
              <button
                class="btn p-2 border-0 text-primary"
                @click="openUpdatemaxhoursModal(maxhour, true)"
              >
                <span
                  v-if="budgetSpendingLoading == maxhour.service_id"
                  class="fa fa-spin fa-circle-notch"
                ></span>
                <span v-else>
                  <b class="mobile-tablet-only mr-2">View</b>
                  <i class="fa fa-search actionIcons"></i>
                </span>
              </button>
              <button
                class="btn p-2 border-0 text-primary"
                @click="openRenewBudgetModal(maxhour)"
              >
                <span
                  v-if="budgetSpendingLoading == maxhour.service_id"
                  class="fa fa-spin fa-circle-notch"
                ></span>
                <span v-else>
                  <b class="mobile-tablet-only mr-2">Renew Budget</b>
                  <i class="fas fa-sync actionIcons"></i>
                </span>
              </button>
              <button
                class="btn p-2 border-0 text-primary"
                v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
                @click="openUpdatemaxhoursModal(maxhour)"
              >
                <b class="mobile-tablet-only mr-2">Edit</b>
                <i class="fa fa-edit actionIcons"></i>
              </button>
              <button
                class="btn p-2 border-0 text-danger"
                v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
                @click="openDeletemaxhoursModal(maxhour)"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <i class="fa fa-trash actionIcons"></i>
              </button>
            </td>
            <td>
              <div class="d-flex align-items-center h-100">
                <button
                  class="btn btn-outline-blue btn-sm text-nowrap w-100"
                  @click="
                    openBudgetHistoryModal(maxhour.user_id, maxhour.service_id)
                  "
                >
                  <span
                    v-if="budgetHistoryLoading == maxhour.service_id"
                    class="fa fa-spin fa-circle-notch"
                  ></span>
                  <span v-else>
                    <i class="fa fa-history actionIcons"></i>
                    Show History
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dateFormat from "@/utils/dateFormat";
import BudgetHistoryModel from "./BudgetHistoryModal.vue";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "patient.maxhours",
  props: ["patientId", "hideDetails"],
  components: {
    BudgetHistoryModel,
  },
  data() {
    return {
      isSaving: false,
      isDeleting: false,
      viewOnly: false,
      showSubServices: false,
      relevantSubServices: [],
      newmaxhours: {
        user_id: "",
        service_id: "",
        value: null,
        period_type: "",
        budget: null,
        period_value: null,
      },
      selectedmaxhours: {
        user_id: "",
        id: "",
        service_id: "",
        value: null,
        period_type: "",
        budget: null,
        period_value: null,
      },
      preSelectedServiceId: -1,
      periodOptions: [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
      ],
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      maxhours: (state) => state.patients.maxhours,
      budgetSpendingData: (state) =>
        state.patients.maxhours.budgetSpending.data,
      budgetSpendingLoading: (state) =>
        state.patients.maxhours.budgetSpending.loading,
      budgetHistoryData: (state) => state.patients.maxhours.budgetHistory.data,
      budgetHistoryLoading: (state) =>
        state.patients.maxhours.budgetHistory.loading,
      services: (state) => state.services.allservices,
      parentServices: (state) => state.services.parentServices,
      clientServices: (state) => state.services.userServices,
    }),
    displayedParentServicesToAdd: function () {
      if (this.parentServices && this.parentServices.length) {
        const client_services = this.parentServices.filter((serv) =>
          this.clientServices.includes(serv.id)
        );
        if ((this.maxhours.data || []).length) {
          const addedMaxhours = this.maxhours.data.map(
            (item) => item.service_id
          );
          return client_services.filter(
            (item) => !addedMaxhours.includes(item.id) && item.unit == "HR"
          );
        }
        return client_services.filter((item) => item.unit == "HR");
      }
      return [];
    },
    displayedParentServicesToEdit: function () {
      if (this.parentServices && this.parentServices.length) {
        const client_services = this.parentServices.filter(
          (serv) =>
            this.clientServices.includes(serv.id) ||
            serv.id == this.preSelectedServiceId
        );
        if ((this.maxhours.data || []).length) {
          const addedMaxhours = this.maxhours.data.map(
            (item) => item.service_id
          );
          return client_services.filter(
            (item) =>
              (!addedMaxhours.includes(item.id) ||
                item.id == this.preSelectedServiceId) &&
              item.unit == "HR"
          );
        }
        return client_services.filter((item) => item.unit == "HR");
      }
      return [];
    },
    getDividedBudgetFrom: function () {
      return (key = "selectedmaxhours") => {
        return (this[key].budget || 0) / (this[key].period_value || 1);
      };
    },
    getRemainingBalance: function () {
      return (this.selectedmaxhours.budget || 0) - this.budgetSpendingData;
    },
    getNow() {
      return dayjs();
    },
  },
  mounted() {
    this.getRelatedmaxhours(this.patient.user_id);
    this.getServices();
    this.getPatientServices(this.patient.user_id);
  },
  methods: {
    ...mapActions({
      getServices: "services/getServices",
      getRelatedmaxhours: "patients/maxhours/get",
      addNewMaxHours: "patients/maxhours/addNewMaxHours",
      deleteSelectedmaxhours: "patients/maxhours/delete",
      updateSelectedmaxhours: "patients/maxhours/update",
      renewSelectedBudget: "patients/maxhours/renew",
      getSpendingBudget: "patients/maxhours/getSpendingBudget",
      getBudgetHistory: "patients/maxhours/getBudgetHistory",
      getPatientServices: "services/getClientServices",
    }),
    displayRelevantSubServices(selectedId, updateMode = false) {
      if (!updateMode) {
        this.relevantSubServices = this.services.filter(
          (item) => item.parent_service_id == selectedId && item.unit == "HR"
        );
        this.relevantSubServices.forEach((service) => {
          service.value = "";
        });
      } else {
        this.relevantSubServices = this.maxhours.allMaxhours.filter(
          (item) => item.parent_maxhour_id == selectedId
        );

        this.relevantSubServices.map((item) => (item.name = item.service_name));
      }
      if (this.relevantSubServices.length > 0) {
        this.showSubServices = true;
      } else {
        this.showSubServices = false;
      }
    },
    groupBy: function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    clearData() {
      this.newmaxhours = {
        user_id: "",
        value: null,
        period_type: "",
        service_id: "",
        budget: null,
        period_value: null,
      };
    },
    updatemaxhoursMiddle(objecToSend) {
      let vm = this;
      objecToSend.maxhours.subServices = this.relevantSubServices.map(function (
        item
      ) {
        return { id: item.id, value: item.value };
      });
      //append sub services if any to the maxhours defined
      this.isSaving = true;
      this.updateSelectedmaxhours(objecToSend).then(function () {
        vm.isSaving = false;
        vm.getRelatedmaxhours(vm.patient.user_id);
        vm.closeUpdatemaxhoursModal();
      });
    },
    addNewmaxhoursMiddle(objecToSend) {
      let vm = this;
      objecToSend.user_id = this.patient.user_id;
      objecToSend.newmaxhours.user_id = objecToSend.user_id;

      objecToSend.newmaxhours.subServices = this.relevantSubServices.map(
        function (item) {
          return {
            id: item.id,
            value: item.value || null,
            parent_service_id: objecToSend.newmaxhours.service_id,
            period_type: objecToSend.newmaxhours.period_type,
          };
        }
      );

      this.isSaving = true;
      this.addNewMaxHours(objecToSend).then(function () {
        vm.isSaving = false;
        vm.clearData();
        vm.getRelatedmaxhours(vm.patient.user_id);
        vm.closeAddmaxhoursModal();
      });
    },
    deleteSelectedmaxhoursMiddle(objecToSend) {
      let vm = this;
      this.isDeleting = true;
      this.deleteSelectedmaxhours(objecToSend).then(function () {
        vm.isDeleting = false;
        vm.getRelatedmaxhours(vm.patient.user_id);
        vm.closeDeletemaxhoursModal();
      });
    },
    renewBudget: function (objectToSend) {
      let vm = this;
      objectToSend.user_id = this.patient.user_id;
      objectToSend.maxhours.user_id = objectToSend.user_id;

      objectToSend.maxhours.subServices = this.relevantSubServices.map(
        function (item) {
          return {
            id: item.id,
            value: item.value || null,
            parent_service_id: objectToSend.maxhours.service_id,
            period_type: objectToSend.maxhours.period_type,
          };
        }
      );
      this.isSaving = true;
      this.renewSelectedBudget(objectToSend).then(function () {
        vm.isSaving = false;
        vm.clearData();
        vm.getRelatedmaxhours(vm.patient.user_id);
        vm.closeRenewBudgetModal();
      });
    },
    openAddmaxhoursModal() {
      this.clearData();
      this.showSubServices = false;
      this.$modal.show("add-maxhours-modal");
    },
    openDeletemaxhoursModal(selectedmaxhours) {
      this.selectedmaxhours = selectedmaxhours;
      this.$modal.show("delete-maxhours-modal");
    },
    openUpdatemaxhoursModal(selectedmaxhours, viewOnly) {
      this.selectedmaxhours = Object.assign({}, selectedmaxhours);
      this.preSelectedServiceId = selectedmaxhours.service_id;
      this.displayRelevantSubServices(this.selectedmaxhours.service_id, true);
      this.viewOnly = viewOnly;
      if (viewOnly) {
        this.getSpendingFor(selectedmaxhours.user_id, selectedmaxhours.id).then(
          (res) => {
            if (res) {
              this.$modal.show("update-maxhours-modal");
            }
          }
        );
      } else {
        this.$modal.show("update-maxhours-modal");
      }
    },
    openRenewBudgetModal(selectedmaxhours, viewOnly) {
      const vm = this;
      this.selectedmaxhours = Object.assign({}, selectedmaxhours);
      this.preSelectedServiceId = selectedmaxhours.service_id;
      this.displayRelevantSubServices(this.selectedmaxhours.service_id, true);
      this.viewOnly = viewOnly;
      this.getSpendingFor(selectedmaxhours.user_id, selectedmaxhours.id).then(
        (res) => {
          if (res) {
            vm.$modal.show("renew-budget-modal");
          }
        }
      );
    },
    openBudgetHistoryModal: function (user_id, service_id) {
      this.getBudgetHistory({
        user_id,
        service_id,
      }).then((res) => {
        if (res) {
          this.$modal.show("budget-history-modal");
        }
      });
    },
    closeAddmaxhoursModal() {
      this.$modal.hide("add-maxhours-modal");
    },
    closeDeletemaxhoursModal() {
      this.$modal.hide("delete-maxhours-modal");
    },
    closeUpdatemaxhoursModal() {
      this.$modal.hide("update-maxhours-modal");
    },
    closeBudgetHistoryModal() {
      this.$modal.hide("budget-history-modal");
    },
    closeRenewBudgetModal() {
      this.$modal.hide("renew-budget-modal");
    },
    getServiceRate: function (serviceId) {
      return this.services.find((record) => record.id == serviceId)?.rate || 0;
    },
    setSubServiceValue: function (index, value) {
      this.$set(this.relevantSubServices, index, {
        ...this.relevantSubServices[index],
        value: value,
      }); //for reactivity
    },
    getSpendingFor: function (userId, budgetId) {
      return this.getSpendingBudget({
        userId,
        budgetId,
      });
    },
    adjustTimePeriod: function (start, end) {
      let startDateTime = `${dateFormat(start, "YYYY-MM-DD")}T00:00:00.000000Z`;
      let endDateTime = `${dateFormat(end, "YYYY-MM-DD")}T23:59:59.000000Z`;

      const canadaOffset = dayjs
        .tz(new Date(startDateTime), "America/Toronto")
        .utcOffset();

      startDateTime = dayjs(startDateTime)
        .add(canadaOffset, "minute")
        .utc()
        .format();
      endDateTime = dayjs(endDateTime)
        .add(canadaOffset, "minute")
        .utc()
        .format();

      return {
        start: startDateTime,
        end: endDateTime,
      };
    },
    prettyDate: function (date, onlyDate = false) {
      if (onlyDate) {
        return dayjs(date).format("MMM DD, YYYY");
      } else {
        return dayjs(date).format("MMM DD, YYYY  hh:mm A");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.sub-services-container {
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
}

.update-maxhours-modal,
.add-maxhours-modal,
.renew-budget-modal,
.budget-history-modal {
  .modal-outer-container {
    height: 100%;

    .body-inner-container {
      height: calc(100% - 35px);
      overflow: hidden auto;

      .sub-services-container {
        max-height: initial;
        overflow: visible;
      }
    }
  }
}
.budget-container {
  border-bottom: 1px solid #d2ddec;
}
.calculated-budget {
  position: relative;
  overflow-x: auto;
  div {
    text-indent: 10px;
  }
  padding: 0.2rem 0.75rem;
  &::before {
    content: "$";
    position: absolute;
    left: 8px;
    top: 16%;
  }
}
.actual-budget-above-zero {
  border-bottom: 2px solid #00d97e;
}
.actual-budget-below-zero {
  color: red;
  border-bottom: 2px solid red;
}
</style>
<style lang="scss">
.budget-info-opacity-1 {
  .form-control[readonly] {
    opacity: 1;
  }
}
.dollar-sign {
  position: relative;
  input {
    text-indent: 13px;
  }
  &::before {
    content: "$";
    position: absolute;
    left: 10px;
    top: 22%;
  }
}
.time-sign {
  position: relative;
  input {
    text-indent: 20px;
  }
  &::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f017";
    position: absolute;
    left: 10px;
    top: 22%;
  }
}
</style>
