var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"budget-history-container"},[_c('table',{staticClass:"table table-sm table-striped mb-0 responsive-mobile-tablet-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Service")]),_c('th',[_vm._v("Limit")]),_c('th',[_vm._v("Total Budget")]),_c('th',{staticClass:"text-center"},[_vm._v("Period Value")]),_c('th',[_c('div',{staticClass:"d-inline-flex align-items-center text-center"},[_vm._v(" Defined Budget "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                `Defined Budget = Total Budget divided by the Period Value<br/>
                Note: Defined Budget = Total Budget if the period value not exist`
              ),expression:"\n                `Defined Budget = Total Budget divided by the Period Value<br/>\n                Note: Defined Budget = Total Budget if the period value not exist`\n              ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"})])]),_c('th',[_vm._v(" Budget Left Amount "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
              `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`
            ),expression:"\n              `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`\n            ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"})]),_c('th',[_vm._v("Created at")]),_c('th',[_vm._v("Expired at")])])]),_c('tbody',_vm._l((_vm.maxHours),function(groupedMaxHour,index){return _c('tr',{key:index},[_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Service Name:")]),_vm._v(" "+_vm._s(_vm.getMainService(groupedMaxHour).service_name)+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Limit:")]),_vm._v(_vm._s(_vm.getTotalMaxHoursFor(groupedMaxHour))+" hrs / "+_vm._s(_vm.getMainService(groupedMaxHour).period_type)+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Total Budget:")]),_vm._v(_vm._s(_vm.getMainService(groupedMaxHour).budget ? `$${parseFloat(_vm.getMainService(groupedMaxHour).budget).toFixed( 2 )}` : "N/A")+" ")]),_c('td',{staticClass:"text-center"},[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Period Value:")]),_vm._v(_vm._s(_vm.getMainService(groupedMaxHour).budget && _vm.getMainService(groupedMaxHour).period_value ? _vm.getMainService(groupedMaxHour).period_value : "N/A")+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v(" Defined Budget "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                `Defined Budget = Total Budget divided by the Period Value<br/>
                Note: Defined Budget = Total Budget if the period value not exist`
              ),expression:"\n                `Defined Budget = Total Budget divided by the Period Value<br/>\n                Note: Defined Budget = Total Budget if the period value not exist`\n              ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"}),_vm._v(" : ")]),_vm._v(" "+_vm._s(_vm.getMainService(groupedMaxHour).budget ? `$${parseFloat( _vm.getMainService(groupedMaxHour).budget / (_vm.getMainService(groupedMaxHour).period_value || 1) ).toFixed(2)} / ${_vm.getMainService(groupedMaxHour).period_type}` : "N/A")+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v(" Budget Left Amount "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`
              ),expression:"\n                `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`\n              ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"}),_vm._v(" : ")]),_c('span',{class:{
              'text-danger':
                _vm.getMainService(groupedMaxHour).budget_left_amount < 0,
            }},[_vm._v(" "+_vm._s(_vm.getMainService(groupedMaxHour).budget_left_amount ? `$${parseFloat( _vm.getMainService(groupedMaxHour).budget_left_amount ).toFixed(2)}` : "N/A")+" ")])]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Created At:")]),_vm._v(_vm._s(_vm.prettyDate(_vm.getMainService(groupedMaxHour).created_at))+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Created At:")]),_c('span',{class:`${
              _vm.getMainService(groupedMaxHour).deleted_at ? '' : 'text-success'
            }`},[_vm._v(" "+_vm._s(_vm.getMainService(groupedMaxHour).deleted_at ? _vm.prettyDate(_vm.getMainService(groupedMaxHour).deleted_at) : "Active")+" ")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }