<template>
  <div class="budget-history-container">
    <table
      class="table table-sm table-striped mb-0 responsive-mobile-tablet-table"
    >
      <thead>
        <tr>
          <th>Service</th>
          <th>Limit</th>
          <th>Total Budget</th>
          <th class="text-center">Period Value</th>
          <th>
            <div class="d-inline-flex align-items-center text-center">
              Defined Budget
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="
                  `Defined Budget = Total Budget divided by the Period Value<br/>
                  Note: Defined Budget = Total Budget if the period value not exist`
                "
              ></i>
            </div>
          </th>
          <th>
            Budget Left Amount
            <i
              class="fa fa-info-circle ml-2 pointer-cursor"
              v-tooltip.top="
                `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`
              "
            ></i>
          </th>
          <th>Created at</th>
          <th>Expired at</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(groupedMaxHour, index) in maxHours" :key="index">
          <td>
            <b class="mobile-tablet-only mr-2">Service Name:</b>
            {{ getMainService(groupedMaxHour).service_name }}
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">Limit:</b
            >{{ getTotalMaxHoursFor(groupedMaxHour) }} hrs /
            {{ getMainService(groupedMaxHour).period_type }}
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">Total Budget:</b
            >{{
              getMainService(groupedMaxHour).budget
                ? `$${parseFloat(getMainService(groupedMaxHour).budget).toFixed(
                    2
                  )}`
                : "N/A"
            }}
          </td>
          <td class="text-center">
            <b class="mobile-tablet-only mr-2">Period Value:</b
            >{{
              getMainService(groupedMaxHour).budget &&
              getMainService(groupedMaxHour).period_value
                ? getMainService(groupedMaxHour).period_value
                : "N/A"
            }}
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">
              Defined Budget
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="
                  `Defined Budget = Total Budget divided by the Period Value<br/>
                  Note: Defined Budget = Total Budget if the period value not exist`
                "
              ></i>
              :
            </b>
            {{
              getMainService(groupedMaxHour).budget
                ? `$${parseFloat(
                    getMainService(groupedMaxHour).budget /
                      (getMainService(groupedMaxHour).period_value || 1)
                  ).toFixed(2)} / ${getMainService(groupedMaxHour).period_type}`
                : "N/A"
            }}
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">
              Budget Left Amount
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="
                  `Budget Left Amount = subtract the total amount of money you have spent from the total amount of money you have budgeted`
                "
              ></i>
              :
            </b>
            <span
              :class="{
                'text-danger':
                  getMainService(groupedMaxHour).budget_left_amount < 0,
              }"
            >
              {{
                getMainService(groupedMaxHour).budget_left_amount
                  ? `$${parseFloat(
                      getMainService(groupedMaxHour).budget_left_amount
                    ).toFixed(2)}`
                  : "N/A"
              }}
            </span>
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">Created At:</b
            >{{ prettyDate(getMainService(groupedMaxHour).created_at) }}
          </td>
          <td>
            <b class="mobile-tablet-only mr-2">Created At:</b>
            <span
              :class="`${
                getMainService(groupedMaxHour).deleted_at ? '' : 'text-success'
              }`"
            >
              {{
                getMainService(groupedMaxHour).deleted_at
                  ? prettyDate(getMainService(groupedMaxHour).deleted_at)
                  : "Active"
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import dayjs from "dayjs";

export default {
  props: ["maxHours"],
  computed: {
    getTotalMaxHoursFor() {
      return (groupedMaxHour) => {
        return groupedMaxHour.reduce((total, maxHour) => {
          return total + maxHour.value;
        }, 0);
      };
    },
    getMainService() {
      return (groupedMaxHour) => {
        return groupedMaxHour.filter(
          (maxHour) => maxHour.parent_maxhour_id == 0
        )?.[0];
      };
    },
  },
  methods: {
    prettyDate: function (date) {
      return dayjs(date).format("MMM DD, YYYY  hh:mm A");
    },
  },
};
</script>
